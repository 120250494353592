import React, { useContext } from "react";
import { Route, Link, useLocation, useRouteMatch } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import {
  TopView,
  FrontView,
  InteriorView,
  Padlock,
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Banner5,
  Banner6,
  Banner7,
  Banner8,
  Banner9,
  Banner10,
  Banner11,
  Banner12,
} from "../../images";
import Contexts from "../../contexts";
import Description from "./tabs/description";
import Siteplan from "./tabs/siteplan";
import OnlineBalloting from "./tabs/onlineBalloting";
import Bookings from "./tabs/bookings";
import { isMobile } from "react-device-detect";

function Detail() {
  const { isRegistered } = useContext(Contexts);
  const { path } = useRouteMatch();
  const location = useLocation();
  const unlockSitePlanDate = moment("2022-01-07");
  const unlockDescriptionTab = moment("2021-01-01");

  return (
    <div className="container-fluid" style={{ marginBottom: 100 }}>
      <div className="row">
        <div className="col px-0">
          <Carousel showThumbs={false} showArrows={false} swipeable={true}>
            <img
              alt="Bandar Rimbayu @ Top View"
              className="img-fluid"
              src={TopView}
            />
            <img
              alt="Bandar Rimbayu @ Banner 1"
              className="img-fluid"
              src={Banner1}
            />
            <img
              alt="Bandar Rimbayu @ Banner 2"
              className="img-fluid"
              src={Banner2}
            />
            <img
              alt="Bandar Rimbayu @ Banner 3"
              className="img-fluid"
              src={Banner3}
            />
            <img
              alt="Bandar Rimbayu @ Banner 4"
              className="img-fluid"
              src={Banner4}
            />
            <img
              alt="Bandar Rimbayu @ Banner 5"
              className="img-fluid"
              src={Banner5}
            />
            <img
              alt="Bandar Rimbayu @ Banner 6"
              className="img-fluid"
              src={Banner6}
            />
            <img
              alt="Bandar Rimbayu @ Banner 7"
              className="img-fluid"
              src={Banner7}
            />
            <img
              alt="Bandar Rimbayu @ Banner 8"
              className="img-fluid"
              src={Banner8}
            />
            <img
              alt="Bandar Rimbayu @ Banner 9"
              className="img-fluid"
              src={Banner9}
            />
            <img
              alt="Bandar Rimbayu @ Banner 10"
              className="img-fluid"
              src={Banner10}
            />
            <img
              alt="Bandar Rimbayu @ Banner 11"
              className="img-fluid"
              src={Banner11}
            />
            <img
              alt="Bandar Rimbayu @ Banner 12"
              className="img-fluid"
              src={Banner12}
            />
          </Carousel>
        </div>
      </div>
      <div className="row">
        <div className="col px-0">
          <div className="d-flex tab">
            <div
              className={`d-flex align-items-center detail-link ${
                location.pathname === "/1/detail" ? "active" : ""
              }`}
            >
              {isRegistered ? (
                <Link
                  to={{ pathname: "/1/detail" }}
                  className="d-flex align-items-center"
                  style={{ letterSpacing: 0.5 }}
                >
                  DESCRIPTION
                </Link>
              ) : (
                <div
                  className="d-flex align-items-center"
                  style={{ letterSpacing: 0.5 }}
                >
                  <img
                    src={Padlock}
                    alt="Padlock"
                    width={11}
                    style={{ marginRight: "0.325rem" }}
                  />
                  DESCRIPTION
                </div>
              )}
            </div>
            <div
              className={`d-flex align-items-center detail-link ${
                location.pathname === "/1/detail/site-plan" ? "active" : ""
              }`}
            >
              {isRegistered && unlockSitePlanDate.isBefore() ? (
                isMobile ? (
                  <a
                    href={process.env.REACT_APP_SITEPLAN_URL}
                    target="_blank"
                    className="d-flex align-items-center"
                    style={{ letterSpacing: 0.5 }}
                  >
                    SITEPLAN
                  </a>
                ) : (
                  <Link
                    to={{ pathname: "/1/detail/site-plan" }}
                    className="d-flex align-items-center"
                    style={{ letterSpacing: 0.5 }}
                  >
                    SITEPLAN
                  </Link>
                )
              ) : (
                <div
                  className="d-flex align-items-center"
                  style={{ letterSpacing: 0.5 }}
                >
                  <img
                    src={Padlock}
                    alt="Padlock"
                    width={11}
                    style={{ marginRight: "0.325rem" }}
                  />
                  SITEPLAN
                </div>
              )}
            </div>
            <div
              className={`d-flex align-items-center text-center detail-link ${
                location.pathname === "/1/detail/online-balloting"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to={{ pathname: "/1/detail/online-balloting" }}
                style={{ letterSpacing: 0.5 }}
              >
                UNIT SELECTION
              </Link>
            </div>
            <div
              className={`d-flex align-items-center text-center detail-link ${
                location.pathname === "/1/detail/bookings" ? "active" : ""
              }`}
            >
              <Link
                to={{ pathname: "/1/detail/bookings" }}
                style={{ letterSpacing: 0.5 }}
              >
                BOOKINGS
              </Link>
            </div>
            <div className="detail-link flex-fill d-none d-sm-block" />
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "2rem" }}>
        <div className="col mx-2">
          <Route exact path={`${path}`} component={Description} />
          <Route path={`${path}/site-plan`} component={Siteplan} />
          <Route
            path={`${path}/online-balloting`}
            component={OnlineBalloting}
          />
          <Route path={`${path}/bookings`} component={Bookings} />
        </div>
      </div>
    </div>
  );
}

export default Detail;
